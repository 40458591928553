/* eslint-disable import/prefer-default-export */
import { Yup, i18next } from '@lib/index';

const { t } = i18next;

export const docTabUploadFormValidation = Yup.object(
  {
    uploadId: Yup.number().required(t('documentation.fileRequired')),
    terms: Yup.boolean().isTrue(t('documentation.termsRequired')),
    indefiniteTerm: Yup.boolean(),
    expiresAt: Yup.string().when(['indefiniteTerm'], {
      is: (indefiniteTerm: boolean) => !indefiniteTerm,
      then: (schema) => schema.required(t('documentation.expirationDateRequired')).ensure(),
      otherwise: (schema) => schema.nullable(),
    }),
  },
);

export const documentReviewFormValidation = Yup.object({
  isRejection: Yup.boolean(),
  revisionComment: Yup.string().when('isRejection', {
    is: true,
    then: (schema) => schema.required(t('alert.commentRequired')).ensure(),
    otherwise: (schema) => schema.nullable(),
  }),
  indefiniteTerm: Yup.boolean(),
  expiresAt: Yup.string().when(['indefiniteTerm'], {
    is: (indefiniteTerm: boolean) => !indefiniteTerm,
    then: (schema) => schema.required(t('documentation.expirationDateRequired')).ensure(),
    otherwise: (schema) => schema.nullable(),
  }),
});
